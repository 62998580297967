// @ts-nocheck
import React, { useState, useRef, useMemo, useContext, useEffect } from "react";
import {
  Button,
  Collapse,
  Box,
  Textarea,
  Text,
  Spinner,
  IconButton,
  Icon,
  VStack,
  useColorModeValue,
  theme,
  useMediaQuery,
  Tooltip,
  Input,
  Flex,
  Divider,
  CloseButton,
  Center,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useDisclosure,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Grid,
  GridItem,
  AlertDescription,
  Alert,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  HStack,
  Select,
  TabPanels,
  Tabs,
  TabList,
  TabPanel,
  Tab,
  useColorMode,
} from "@chakra-ui/react";
import { ChevronDownIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import moment from "moment";
import classNames from "classnames";
import { FiEdit3 } from "react-icons/fi";
import { HiOutlineDocumentAdd, HiOutlineLightningBolt } from "react-icons/hi";
import { BsCheck } from "react-icons/bs";
import { FaRobot } from "react-icons/fa";
import { GrUndo, GrRedo } from "react-icons/gr";
import { TiTickOutline } from "react-icons/ti";
import shortid from "shortid";
import useUndoableState from "../../hooks/UndoRedoHook.tsx";
import { ThreadTweetPreviewSidebar } from "./ThreadTweetPreviewSidebar";
import { firebaseClient } from "../../firebaseClient";
import { predict } from "controllers/popularity";
import { useSession } from "next-auth/client";
import twitter from "twitter-text";
import { BiCopy } from "react-icons/bi";
import {
  MdSuperscript,
  MdSubscript,
  MdFormatBold,
  MdFormatItalic,
  MdStrikethroughS,
  MdCode,
  MdFormatUnderlined,
} from "react-icons/md";
import { CgFormatItalic } from "react-icons/cg";
import { motion } from "framer-motion";
import {
  replaceSelectedWithUnicode,
  keepRefreshSelection,
} from "./text-to-unicode";
import {
  FilePond,
  registerPlugin,
  create,
  destroy as destroyPond,
} from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { BsFillImageFill } from "react-icons/bs";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import "emoji-mart/css/emoji-mart.css";
import data from "emoji-mart/data/twitter.json";
import { NimblePicker } from "emoji-mart";
import * as Sentry from "@sentry/nextjs";
import autosize from "autosize";
import { TweetContext } from "../../context/tweetContext";
import { MainContext } from "../../context/mainContext";
import { continuePost } from "../../controllers/search";
import { generate } from "../../controllers/variations";
import { saveTweet } from "../../controllers/schedule";
import toast from "react-hot-toast";
import { getAccount, updateUser } from "../../utils/sessionHelper";
import {
  extractImages,
  cleanTweetForCountChar,
  hexToHexa,
  hslToHsla,
} from "../../utils/helpers";
import { ImageThumbnail } from "./image-thumbnail";
import { Suggestions } from "components/suggestions";
import { useSuggestions } from "components/suggestions/useSuggestions";
import { useDebouncedCallback } from "use-debounce";
import { getImageSize, getVideoInfo } from "../../utils/uploadUtils";
import { useFirebaseUser } from "../../utils/useFirebaseUser";
import { useOnClickOutside } from "../../utils/helpers";
import { useDropzone } from "react-dropzone";
import * as Bowser from "bowser";
import { AiOutlineGif } from "react-icons/ai";
import { GifBox } from "components/gif-box";
import StringSimilarity from "string-similarity";
import { isMobile } from "react-device-detect";
import { uploadOnS3 } from "utils/uploadUtils";
import { searchUsers } from "controllers/miscellaneous";
import { ImageEditPopup } from "components/popups/imageEditPopup";
import { useOnboardingSteps } from "components/onboard-panel/useOnboardingSteps";
import { hideTweet } from "controllers/bookmark";
import { Snippets } from "components/snippets";
import { AiButtonSVGIcon } from "./icons";
import { prompts } from "./utils";
import { useChatContext } from "context/chatContext";
import { layerStyle, radius, textStyle, variant } from "theme/names";
import { border } from "theme/foundations/border";
import { InfoIconSVG } from "components/icons/InfoIconSVG";
import { colors } from "theme/colors/colors";
import { SaveIcon } from "components/icons/SaveIcon";
import { PlusIcon } from "components/icons/PlusIcon";
import { EditIconSVG } from "components/icons/EditIconSVG";
import { EditIconComponent } from "components/icons/EditIconComponent";
import { color as colorNames } from "theme/names";
import { Bolt } from "components/icons/Bolt";
import { FastForward } from "components/icons/FastForward";
import { AIReplyButtonsMemoed } from "./ai-reply-buttons";
import { useWindowWidth } from "@react-hook/window-size";
import GenerateImage from "./generate-image";
import { createThumbnail, getUserMedias, postMedia } from "controllers/media";
import MediaMasonry, { MediaObject } from "components/media-masonry";
import Loader from "react-spinners/PuffLoader";
import { isAllowed } from "controllers/subscription";
import { sanitizeInput } from "utils/sanitizationHelper";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

// const multer = require('multer');
// const upload = multer();

const BUCKET_NAME = process.env.NEXT_PUBLIC_PROJECT_BUCKET;

// import AWS from 'aws-sdk';
// const multer = require('multer');
// const upload = multer();

// const ID = 'AKIAIRPTCTFOIUGQURKQ';
// const SECRET = '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';
// const BUCKET_NAME = 'ez4cast';

// const s3 = new AWS.S3({
//     accessKeyId: ID,
//     secretAccessKey: SECRET
// });

const baseStyle = {};

const activeStyle = {
  borderColor: "#2196f3",
  borderWidth: 2,
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const uploadingFiles = [];

let browser;
if (typeof window !== "undefined")
  browser = Bowser.getParser(window.navigator.userAgent);

const filePondLable = `
    <div class="filepond--icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 13C21.7348 13 21.4804 13.1054 21.2929 13.2929C21.1054 13.4804 21 13.7348 21 14V18.213C20.9992 18.9519 20.7053 19.6603 20.1828 20.1828C19.6603 20.7053 18.9519 20.9992 18.213 21H5.787C5.04809 20.9992 4.33966 20.7053 3.81717 20.1828C3.29468 19.6603 3.00079 18.9519 3 18.213V14C3 13.7348 2.89464 13.4804 2.70711 13.2929C2.51957 13.1054 2.26522 13 2 13C1.73478 13 1.48043 13.1054 1.29289 13.2929C1.10536 13.4804 1 13.7348 1 14V18.213C1.00132 19.4822 1.50609 20.699 2.40354 21.5965C3.30099 22.4939 4.51782 22.9987 5.787 23H18.213C19.4822 22.9987 20.699 22.4939 21.5965 21.5965C22.4939 20.699 22.9987 19.4822 23 18.213V14C23 13.7348 22.8946 13.4804 22.7071 13.2929C22.5196 13.1054 22.2652 13 22 13Z" fill="#828282"/>
        <path d="M6.707 8.70703L11 4.41403V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8947 11.7348 18 12 18C12.2652 18 12.5196 17.8947 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V4.41403L17.293 8.70703C17.4816 8.88919 17.7342 8.98998 17.9964 8.9877C18.2586 8.98543 18.5094 8.88026 18.6948 8.69485C18.8802 8.50944 18.9854 8.25863 18.9877 7.99643C18.99 7.73423 18.8892 7.48163 18.707 7.29303L12.707 1.29303C12.5195 1.10556 12.2652 1.00024 12 1.00024C11.7348 1.00024 11.4805 1.10556 11.293 1.29303L5.293 7.29303C5.11085 7.48163 5.01005 7.73423 5.01233 7.99643C5.01461 8.25863 5.11978 8.50944 5.30518 8.69485C5.49059 8.88026 5.7414 8.98543 6.0036 8.9877C6.2658 8.98998 6.5184 8.88919 6.707 8.70703Z" fill="#828282"/>
        </svg>
    </div>
    <div>Click to <span class="filepond--label-action">Upload</span> or drag & drop</div>
`;
const isFileValid = async (file) => {
  if (!file.type.includes("image") && !file.type.includes("video")) {
    toast.error("Add image and video only");
    return false;
  }

  if (
    file.type.includes("image") &&
    !["image/png", "image/jpeg", "image/gif"].includes(file.type)
  ) {
    toast.error("Unsupported image format");
    return false;
  }

  if (["image/png", "image/jpeg"].includes(file.type) && file.size > 5000000) {
    toast.error("Images cannot be bigger than 5mb");
    return false;
  } else if (["video/webm"].includes(file.type)) {
    // used to be unsupported - "video/quicktime"
    toast.error("Video format not supported");
    return false;
  } else if (["image/gif"].includes(file.type) && file.size > 15000000) {
    toast.error("Gifs cannot be bigger than 15mb");
    return false;
  } else if (file.size > 128000000) {
    toast.error("Files cannot be bigger than 128mb");
    return false;
  }

  if (!file.type.includes("video")) {
    let size = await getImageSize(file);
    // console.log(size);
    if (
      size.height < 4 ||
      size.height > 8192 ||
      size.width < 4 ||
      size.width > 8192
    ) {
      toast.error("Image dimensions must be >= 4x4 and <= 8192x8192");
      return false;
    }
  } else if (file.type.includes("video")) {
    let videoData = await getVideoInfo(file);
    // console.log('video duration:', videoData);
    if (videoData?.duration >= 140) {
      toast.error("Video can't exceed 140 seconds in duration");
      return false;
    }
    if (videoData?.bitrate >= 60000000) {
      toast.error(
        "The video bitrate is too high and is not supported by Twitter"
      );
      return false;
    }
  }

  return true;
};
export const TweetComposer = React.forwardRef((props: any, ref) => {
  //@ts-ignore
  let {
    id,
    setId,
    createDraft,
    newTweet,
    initText,
    initialTextStates,
    initFiles,
    initThreadTweets,
    disableHeader = false,
    isFullMode = false,
    submit = null,
    onUpdate = null,
    disableImage = false,
    isDisabled = false,
    disableThread = false,
    disableSnippets = false,
    disableRichText = false,
    customSave = undefined,
    mode = "tweet",
    isPostTwitter = true,
    disableReWrite = false,
    disableAiContinue = false,
    isFocused = false,
    removeTweet = undefined,
    likeAndRemove = undefined,
    tweet = undefined,
    disableGif = false,
    selectedTweet = undefined,
    disablePreview = false,
    disableSuggestion = false,
    imageEditor = false,
    placeHolder = `Write here. \n\n\nSkip 3 lines to start a thread.`,
    nbCharMax = 280,
    onNewDraft = () => {},
  } = props;
  const initialTextState = {
    id: id,
    text: initText ? initText : "",
    typingTimeout: undefined,
    isSaved: false,
    scheduledTime: undefined,
    files: initFiles ? initFiles : [],
    threadTweets: initThreadTweets ? initThreadTweets : [],
  };

  const [isSaving, setIsSaving] = React.useState(false);
  const [isSaved, setIsSaved] = React.useState(false);
  const [nbChar, setNbChar] = React.useState(0);
  const [tweetError, setTweetError] = React.useState({});
  // const [textState, setTextState] = React.useState(initialTextState);
  const {
    state: textState,
    states: textStates,
    setState: setTextState,
    resetState: resetTextState,
    index: textStateIndex,
    lastIndex: textStateLastIndex,
    goBack: undoTextState,
    goForward: redoTextState,
  } = useUndoableState({
    initState: initialTextState,
    initStates: initialTextStates,
  });

  const canUndo = textStateIndex > 0;
  const canRedo = textStateIndex < textStateLastIndex;

  const [session, loading] = useSession();
  const isMountedRef = React.useRef(false);

  // const [isTooSmallForThread] = useMediaQuery("(max-width: 800px)");
  const screenWidth = useWindowWidth();
  const isTooSmallForThread = screenWidth < 800;

  const tweetContext: any = React.useContext(TweetContext);
  const chatContext: any = useChatContext();
  const mainContext = useContext(MainContext);
  const isAuthenticated = useFirebaseUser();

  const [isSnippetsOpen, setIsSnippetsOpen] = React.useState(false);
  const [isRichTextOpen, setIsRichTextOpen] = React.useState(false);

  const [isGenerating, setIsGenerating] = React.useState(false);
  const [isReWriting, setIsReWriting] = React.useState(false);
  const [isSavingImage, setIsSavingImage] = React.useState(false);
  const refTriggerEmoji = React.useRef();
  const refTextArea = React.useRef(null);
  const refData = React.useRef();
  const refId = React.useRef();
  const {
    isSuggestionsOpen,
    setIsSuggestionOpen,
    checkForSuggestions,
    suggestions,
    setSuggestions,
    updateText,
    disableArrowKeys,
    position,
  } = useSuggestions(refTextArea);
  refId.current = id;

  const refPond = React.useRef();
  // const [files, setFiles] = React.useState(initFiles ? initFiles : []);
  const backgroundColor = useColorModeValue("white", "#1E1E1E");
  const aiOptionPopoverBg = useColorModeValue("white", "#1E1E1E");
  const aiOptionHeaderColor = useColorModeValue("#0A66C2", "linkedin.300");
  const aiOptionActionButtonHoverBg = useColorModeValue("#f6f6f6", "#252525");
  const { colorMode } = useColorMode();
  const debounced = useDebouncedCallback(checkForSuggestions, 400);

  const [alwaysShowSidebar, setAlwaysShowSidebar] = useState<boolean>(false);
  const [hasInit, setHasInit] = useState<boolean>(false);
  const [needReset, setNeedReset] = useState<boolean>(false);

  const [popularity, setPopularity] = useState({
    prediction: 0,
    proba_outperforming: 0,
    confidence: 0.5,
  });
  // const [popularity, setPopularity] = useState({prediction: -1, proba_outperforming: -1, confidence: -1});
  const [isLoadingPop, setIsLoadingPop] = useState<boolean>(false);
  const [isShowPrediction, setIsShowPrediction] = useState<boolean>(false);
  const [alwaysShowPrediction, setAlwaysShowPrediction] =
    useState<boolean>(false);
  const [accountForPrediction, setAccountForPrediction] = useState("");
  const [isLoadingAccountPrediction, setIsLoadingAccountPrediction] =
    useState<boolean>(false);
  const [previousPopularityText, setPreviousPopularityText] =
    useState<string>("");

  const [isOpenEmoji, setIsOpenEmoji] = React.useState(false);
  const [isGifOpen, setIsGifOpen] = useState<boolean>(false);
  const [isSavingGif, setIsSavingGif] = useState<boolean>(false);
  const [selectedImageId, setSelectedImageId] = useState<string>("");
  const [medias, setMedias] = useState<MediaObject[]>([]);
  const [hasFetchedMedia, setHasFetchedMedia] = useState<boolean>(false);
  const [selectedMedias, setSelectedMedias] = useState<string[]>([]);
  const [isPremium, setIsPremium] = useState(false);
  const [isShowThreadTweetPreview, setIsShowThreadTweetPreview] =
    useState(false);
  const [postCharMax, setPostCharMax] = useState(nbCharMax);
  const [tabIndex, setTabIndex] = useState(0);
  const refEmoji = React.useRef<any>();
  const suggestionPopupRef = useRef(null);
  const refGif = useRef(null);

  const gifContainerBorder = useColorModeValue("#ccd0d5", "whiteAlpha.400");
  const gifContainerBg = useColorModeValue("white", "#1E1E1E");
  const borderColor = useColorModeValue("gray.100", "whiteAlpha.400");
  const nimblePickerTheme = useColorModeValue("light", "dark");
  const engageContainerBg = useColorModeValue("white", "#3b3b3b");

  const {
    isOpen: isOpenRewrite,
    onOpen: onOpenRewrite,
    onClose: onCloseRewrite,
  } = useDisclosure();
  const {
    isOpen: isOpenImageEdit,
    onOpen: onOpenImageEdit,
    onClose: onCloseImageEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenImageUpload,
    onOpen: onOpenImageUpload,
    onClose: onCloseImageUpload,
  } = useDisclosure();
  const { updateOnboardSteps } = useOnboardingSteps();

  const onFileUpload = async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setIsSavingImage(true);
      let file = acceptedFiles[0];
      if (!(await isFileValid(file))) {
        setIsSavingImage(false);
        setIsSavingGif(false);
        return;
      }

      let isGif = file.type.includes("gif");
      let isVideo = file.type.includes("video");
      let id = "";

      if (isVideo) id += "vid-";

      if (isGif) {
        id += "gif-";
      }
      id += shortid.generate().replace("-", "_");
      const params = {
        Bucket: BUCKET_NAME,
        Key: "userUpload/" + id,
        Body: file,
        ACL: "public-read",
      };
      try {
        await uploadOnS3(params);
        addFileOnThread(id);
        onCloseImageUpload();
      } catch (error) {
        toast.error("Error while uploading file");
      }
      setIsSavingImage(false);
      setIsSavingGif(false);
    }
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/*,video/*",
    onDropRejected: () => toast.error("Add image and video only"),
    onDrop: onFileUpload,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const fetchData = async () => {
    const response = await getUserMedias(session);
    if (response?.success) {
      setMedias(response?.data);
      setHasFetchedMedia(true);
    }
  };

  useOnClickOutside(refEmoji, async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    setIsOpenEmoji(false);
  });
  useOnClickOutside(suggestionPopupRef, async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    setIsSuggestionOpen(false);
    if (refTextArea?.current?.focus) {
      refTextArea.current.focus();
    }
  });
  useOnClickOutside(refGif, async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    setIsGifOpen(false);
  });

  const focus = async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    refTextArea?.current?.focus();
  };

  React.useEffect(() => {
    if (!hasInit && isAuthenticated) {
      setHasInit(true);
      setAlwaysShowSidebar(getAccount(session)?.alwaysShowSidebar ?? false);
      // setAlwaysShowPrediction(getAccount(session)?.alwaysShowPrediction ?? true);
      // setIsShowPrediction(getAccount(session)?.alwaysShowPrediction ?? true);
      // setAccountForPrediction(getAccount(session)?.accountForPrediction ?? "");
    }
  }, [isAuthenticated]);

  React.useEffect(() => {
    // console.log("TweetComposer", isFocused);
    if (isFocused) {
      focus();
    }
  }, [isFocused]);

  React.useEffect(() => {
    autosize(refTextArea.current);
    return () => {
      autosize.destroy(refTextArea.current);
    };
  }, []);

  const forceUpdateAutosize = function () {
    setTimeout(() => {
      autosize.update(refTextArea.current);
    }, 100);
  };

  React.useEffect(() => {
    forceUpdateAutosize();
  }, [
    tweetContext.isOpen,
    tweetContext.isEditAndTweet,
    isGenerating,
    tweetContext.isTweetTextChanged,
  ]);

  React.useImperativeHandle(ref, () => ({
    textState: () => {
      return textState;
    },
    pushText,
    pushOnThread,
    editThread,
    setTextState: (state) => {
      // console.log(state);
      updateState(state);
    },
    textStates: () => {
      return textStates;
    },
    editText: (text) => {
      console.log("useImperativeHandle");
      editText(text);
    },
    focus: focus,
    resetUploader: (newFiles) => {},
    simulateInput: () => {
      // console.log("simulateInput");
      var event = new Event("input", { bubbles: true });
      var node = document.getElementById("tweet-area");
      node.dispatchEvent(event);
    },
  }));

  React.useEffect(() => {
    if (mode == "taplio-push") {
      let text = textState?.text;
      setNbChar(text.trimEnd()?.length);
    } else if (textState?.text?.length) {
      let text = textState?.text;
      if (!textState?.threadTweets || textState?.threadTweets?.length <= 1) {
        text = cleanTweetForCountChar(text);
      }
      setNbChar(twitter.parseTweet(text).weightedLength);
    } else setNbChar(0);

    // probably causing the cursor bug -> commenting
    // var event = new Event('input', { bubbles: true });
    // var node = document.getElementById('tweet-area');
    // node.dispatchEvent(event);
  }, [textState]);

  // console.log("refId: " + refId.current);
  // console.log("render TweetComposer " + textState.text);

  React.useEffect(() => {
    if (mode == "tweet" && tweetContext?.textState?.text !== undefined) {
      // console.log("erasing textState from tweetContext", tweetContext.textState);
      updateState(tweetContext.textState);
    }
  }, [tweetContext.textState]);

  React.useEffect(() => {
    // console.log(mode + " - initText  changed: " + initText);
    // console.log(mode + " - initFiles changed: " + initFiles);
    textState.text = initText;
    textState.files = initFiles;
    updateState(textState);
    forceUpdateAutosize();
    // console.log("forceUpdateAutosize - mode: " + mode);
    // }, [initText, initFiles]);
  }, [initText, initFiles]);

  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  React.useEffect(() => {
    if (isOpenImageUpload) return;
    setMedias([]);
    setHasFetchedMedia(false);
  }, [isOpenImageUpload]);

  React.useEffect(() => {
    // force refresh when id change (for thread to display nicely)
    console.log("tweet composer force refresh - id: " + id);

    setPopularity({ prediction: 0, proba_outperforming: 0, confidence: 0.5 });
    setTweetError({});

    // if un-commented, this will mess with switching back from full-screen
    // editText(textState?.text);
  }, [id, tweetContext.refresh]);

  useEffect(() => {
    if (Boolean(session) === true && isAuthenticated) {
      const userIsPremium = getAccount(session)?.isPremium;
      setIsPremium(userIsPremium);
      setPostCharMax(userIsPremium ? 25000 : 280);
    }
  }, [session, isAuthenticated]);

  const handleKeyDown = (event) => {
    disableArrowKeys(event);
    // console.log(event.key);
    // console.log(event.ctrlKey);
    if (
      event.key === "Enter" &&
      event.shiftKey &&
      (event.ctrlKey || event.metaKey)
    ) {
      console.log("likeAndRemove");
      likeAndRemove && likeAndRemove();
    } else if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
      console.log("submit");
      submit && submit();
    } else if (event.key === "i" && (event.ctrlKey || event.metaKey)) {
      console.log("hide");
      if (session?.user?.uid && tweet?.id_str)
        hideTweet(tweet, session?.user?.uid, -1);
      removeTweet && removeTweet(tweet);
    } else if (event.key === "e" && (event.ctrlKey || event.metaKey)) {
      console.log("show emoji");
      setIsOpenEmoji(true);
    }
  };

  const updateState = (newState) => {
    // console.log("updateState " + JSON.stringify(newState));
    let newObject = {
      ...newState,
      threadTweets: newState?.text?.split("\n\n\n") ?? [],
    };

    setTextState(newObject);
    // tweetContext.setTextState({ ...newState }); // make everything to lag
    refData.current = newState;
  };

  const pushText = async (text) => {
    textState.text = (textState.text ?? "") + text;
    editText(textState.text);
    tweetContext.setIsTweetTextChanged(true);
  };
  const pushOnThread = async (text, idx) => {
    const contextTextState = tweetContext?.refComposer?.current?.textState();
    let textState =
      mode !== "autoPlug"
        ? contextTextState
          ? contextTextState
          : textState
        : {
            ...initialTextState,
            ...refData.current,
            threadTweets: [refData?.current?.text],
          };

    if (textState?.threadTweets?.[idx] === undefined) {
      textState.threadTweets[idx] = "";
    }
    textState.threadTweets[idx] += " " + text;
    textState.text = textState.threadTweets.join("\n\n\n");
    editText(textState.text);
    tweetContext.setIsTweetTextChanged(true);
  };

  const editThread = async (text, idx) => {
    textState.threadTweets[idx] = text;
    textState.text = textState.threadTweets.join("\n\n\n");
    editText(textState.text);
    tweetContext.setIsTweetTextChanged(true);
  };

  // const debouncedPredict = useDebouncedCallback(async (value) => {
  //     if (isMountedRef.current) {
  //         if (mode == "tweet" && value?.length > 20) {
  //             let threads = value.split("\n\n\n");
  //             let text = threads[0];
  //             if (text !== previousPopularityText) {
  //                 setIsLoadingPop(true);
  //                 setPreviousPopularityText(text);
  //                 predict(session, text).then(predictions => {
  //                     setPopularity(predictions);
  //                     // setPopularity({prediction: -1, proba_outperforming: -1, confidence: -1});
  //                     setIsLoadingPop(false);
  //                 });
  //             }
  //         }
  //         else {
  //             setPopularity({ prediction: 0, proba_outperforming: 0, confidence: 0.5 });
  //         }
  //     }
  // }, 4000)

  const editText = async (value: string, updatedFiles = null) => {
    // console.log("editText " + refId.current + " - new text: " + value);

    // console.log("editText id: " + refId.current + " files: " + JSON.stringify(updatedFiles));
    value = sanitizeInput(value);
    setIsSaving(true);
    // setText(e.target.value);

    if (refData.current.typingTimeout)
      clearTimeout(refData.current.typingTimeout);
    if (refData.current.predictTimeout)
      clearTimeout(refData.current.predictTimeout);

    let newState = {
      ...refData.current,
      id: refId.current,
    };

    // console.log(newState);

    if (value || value === "") {
      let tweets = value.split("\n\n\n");
      // console.log(tweets);
      newState.threadTweets = tweets;
      newState.text = value;

      let images = extractImages(value);
      newState.files = images;
      if (selectedMedias !== images) {
        setSelectedMedias(images);
      }
    }

    // if (updatedFiles)
    //     newState.files = updatedFiles;

    if (createDraft || customSave) {
      newState.typingTimeout = setTimeout(async () => {
        if (isMountedRef.current) {
          checkError(value);
          const dataToSave: any = { text: value };

          let hasSaved = await saveTweet(
            session,
            refId.current,
            dataToSave,
            createDraft,
            customSave
          );
          if (hasSaved) setIsSaving(false);
        }
      }, 400);

      // debouncedPredict(value);
    }

    // console.log(newState);
    updateState(newState);
    onUpdate && onUpdate(newState);
  };

  const addFileOnThread = async (fileName) => {
    if (refTextArea?.current?.focus) refTextArea.current.focus();
    if (refTextArea?.current?.selectionStart) {
      const cursorPos = refTextArea.current.selectionStart;
      const textBeforeCursor = refTextArea.current.value.substring(
        0,
        cursorPos
      );
      const threadIndex = textBeforeCursor.split("\n\n\n").length - 1;
      // console.log("threadIndex: " + threadIndex);
      if (threadIndex > 0) {
        pushOnThread(`[img:${fileName}]`, threadIndex);
      } else {
        pushOnThread(`[img:${fileName}]`, 0);
      }
    } else {
      pushOnThread(`[img:${fileName}]`, 0);
    }
  };

  const checkError = (value: string) => {
    let newError = {};

    if (!value?.match) return;

    let countMention = (value.match(/@/g) || [])?.length;
    if (countMention >= 5) {
      newError = {
        text: "Keep your account safer by not performing unsolicited mentions in bulk. This can be associated to spam and may be flagged by Twitter.",
        type: "error",
      };
    } else if (value[0] == "@") {
      newError = {
        text: "Starting your tweet with a mention drastically reduces its reach.",
        type: "warning",
      };
    }

    setTweetError(newError);
  };

  const handleSelect = (handle: string) => {
    updateText({ currentText: textState?.text, handle }, (updatedText) => {
      editText(updatedText);
    });
  };

  const getSimilarityWarning = () => {
    if (
      !selectedTweet?.full_text ||
      tweetContext?.selectedTweet?.isVariation ||
      !textState?.text ||
      selectedTweet?.user?.id_str == getAccount(session)?.idAccount ||
      selectedTweet?.user?.screen_name == getAccount(session)?.twUserName
    )
      return;

    let similarity = StringSimilarity.compareTwoStrings(
      selectedTweet?.full_text,
      textState?.text
    );
    // console.log("similarity score: " + similarity);
    let message = "";
    let color = "gray";

    // let message = "";
    let toolTipMessage =
      "Your tweet is too similar to the original tweet. Please give it your unique touch by changing or adding more content.";
    // let color = "gray";
    let status = "";

    if (similarity > 0.99) {
      message = "⚠️ Too similar";
      color = "red.500";
      status = "error";
    } else if (similarity > 0.9) {
      message = "Keep going";
      color = "orange.600";
      status = "warning";
    } else if (similarity > 0.85) {
      message = "Almost there";
      color = "orange.400";
      status = "warning";
    } else {
      message = "All good";
      toolTipMessage =
        "This tweet is different from the original, you're good to go.";
      color = "green.400";
      status = "success";
    }

    return (
      <Flex justifyContent="flex-end">
        <Tooltip label={toolTipMessage}>
          {/* {message} */}
          <Alert status={status}>
            <AlertDescription>{message}</AlertDescription>
          </Alert>
        </Tooltip>
      </Flex>
    );
  };

  const handleSetShowSidebar = async () => {
    setAlwaysShowSidebar(!alwaysShowSidebar);
    await updateUser(session, { alwaysShowSidebar: !alwaysShowSidebar });
  };

  const handleSetShowPrediction = async () => {
    setAlwaysShowPrediction(!alwaysShowPrediction);
    await updateUser(session, { alwaysShowPrediction: !alwaysShowPrediction });
  };

  const handleAIReWriteClick = async () => {
    if (!isAuthenticated) {
      toast.error("Sign in to use this feature");
      return;
    }

    updateOnboardSteps("aiWriting", "schedule");

    setIsReWriting(true);
    const tweet = { full_text: textState?.text };
    let data = await generate(session, tweet);
    setIsReWriting(false);
    if (data?.data?.variations?.length) {
      const newText = data.data.variations[0];
      editText(newText);
    } else {
      toast.error("Failed to re-write");
    }
  };

  const handleAIGenerationIconClick = async () => {
    if (!isAuthenticated) {
      toast.error("Sign in to use this feature");
      return;
    }

    if (!textState?.text) {
      toast.error("You need to write something so the AI can continue.");
      return;
    }

    setIsGenerating(true);
    let params = {
      text: textState?.text,
      description: getAccount(session)?.description,
      keywords: getAccount(session)?.keywords,
      twUserName: getAccount(session)?.twUserName,
      model: session?.user?.data?.creditsAuto > 0 ? "j1-jumbo" : "j1-large",
      idUser: getAccount(session)?.id,
    };
    let data = await continuePost(session, params, mainContext);
    setIsGenerating(false);

    if (data?.data?.generations?.length) {
      let text = data.data.generations[0];
      let newText = textState?.text + text;
      editText(newText);

      if (session?.user?.data?.creditsAuto > 0) {
        const db = firebaseClient.firestore();
        await db
          .collection("users")
          .doc(session.user.uid)
          .update({
            creditsAuto: firebaseClient.firestore.FieldValue.increment(-1),
          });
        session.user.data.creditsAuto--;
      }
    } else {
      toast.error("Failed to generate");
    }
  };

  const handleOnSelectEmoji = (e) => {
    // console.log(e);
    const emoji: string = e.native;
    if (document.execCommand) {
      // document.execCommand is absolute but it //add support for undo redo and insert emoji at carrot position
      if (refTextArea?.current?.focus) refTextArea.current.focus();
      var event = new Event("input");
      document.execCommand("insertText", false, emoji);
      return;
    } else {
      let newText = textState?.text + emoji;
      editText(newText);
    }
  };

  const handleUseMediaSelection = () => {
    if (selectedMedias.length === 0) {
      toast.error("Please select at least one media");
      return;
    }
    selectedMedias.forEach((media) => {
      addFileOnThread(media);
    });
    if (mode !== "autoPlug") tweetContext.setFiles(selectedMedias);
    onCloseImageUpload();
  };
  const handleOnSelectGIF = async (item) => {
    try {
      setIsGifOpen(false);
      setIsSavingGif(true);
      const res = await fetch(item.images.original.url);
      const blob = await res.blob();
      const gifFile = new File([blob], item.title, { type: blob.type });
      onFileUpload([gifFile]);
      // if (refPond?.current?.addFile) {
      //     refPond.current.addFile(gifFile);
      // }
    } catch (e) {
      setIsSavingGif(false);
    }
  };

  const handleBeforeAddFile = async (item) => {
    // console.log("beforeAddFile");
    // console.log(item);
    // console.log("size: " + item.file.size);
    console.log("size: " + item.file.size);
    console.log("type: " + item.file.type);

    // console.log(uploadingFiles);
    if (uploadingFiles.includes(item.file.name)) {
      console.error("already uploading this image");
      return false;
    }

    if (!(await isFileValid(item.file))) {
      return false;
    }

    uploadingFiles.push(item.file.name);
    return true;
  };

  const handleOnUpdateFiles = (filesUpdated) => {
    filesUpdated.forEach((x) => {
      if (!x.getMetadata("fileName")) {
        let id = "";
        if (x.file.type.includes("video")) {
          id += "vid-";
        }

        if (x.file.type.includes("gif")) {
          id += "gif-";
        }

        id += shortid.generate();

        let fileName = id;
        let link =
          "https://ez4cast.s3.eu-west-1.amazonaws.com/userUpload/" + fileName;

        x.setMetadata("id", id);
        x.setMetadata("fileName", fileName);
        x.setMetadata("link", link);
      }
    });

    let processFiles = filesUpdated.map((x) => x.getMetadata("link"));
  };

  const handleProcessFile = (error, file) => {
    if (uploadingFiles.includes(file.file.name))
      uploadingFiles.splice(uploadingFiles.indexOf(file.file.name), 1);

    if (error) {
      toast.error("An error occurred while uploading your content");
    } else {
      let newLink = file.getMetadata("link");
      let fileName = file.getMetadata("fileName");

      refData.current.files = [...(refData.current.files || []), newLink];

      addFileOnThread(fileName);
      onCloseImageUpload();
    }
  };
  const handleFilePondServerProcess = async (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options
  ) => {
    if (file.type !== "image/gif") {
      setIsSavingImage(true);
    }

    let fileName = metadata.fileName;

    // console.log("upload metadata.fileName: " + metadata.fileName);
    // console.log("upload file.nam: " + file.name);
    // console.log(file);

    try {
      // console.log("meta: " + file.metadata);
      // console.log(file);
      // const fullFile = fs.readFileSync(file.path);
      const params = {
        Bucket: BUCKET_NAME,
        Key: "userUpload/" + fileName, // File name you want to save as in S3
        Body: file,
        ACL: "public-read",
        // CacheControl: 'public, max-age=31536000',
      };

      try {
        var url = await uploadOnS3(params);

        load(url);
        await postMedia(session, {
          url: url,
          type: file.type,
          fileName: fileName,
          size: file.size,
          id: metadata.id,
        });
        if (file.type.includes("video")) {
          await createThumbnail(session, { id: metadata.id });
        }
        setIsSavingImage(false);
        setIsSavingGif(false);
      } catch (e) {
        console.error(e);
        setIsSavingImage(false);
        setIsSavingGif(false);
        throw e;
      }
    } catch (e) {
      console.error(e);
      console.log("Error in upload: " + e, JSON.stringify(e));
      // message = `Upload error: ${e.message}`;
    }
    return {
      abort: () => {
        setIsSavingImage(false);
        setIsSavingGif(false);
      },
    };
  };

  const renderNewDraftButton = () => {
    return (
      <Button
        ml="2"
        variant={variant.Button.tertiaryNoBackground}
        fontWeight="400"
        color="gray.500"
        // fontSize={'sm'}
        size="sm"
        onClick={async () => {
          newTweet({ text: "", labels: [], statusGhost: undefined });
          tweetContext.refresher({});
          tweetContext.setIsTweetTextChanged(true);
          onNewDraft();
        }}
        // leftIcon={<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        // <path d="M13.5 8C13.5 8.4375 13.1562 8.75 12.75 8.75H7.75V13.75C7.75 14.1875 7.40625 14.5312 7 14.5312C6.5625 14.5312 6.25 14.1875 6.25 13.75V8.75H1.25C0.8125 8.75 0.5 8.4375 0.5 8.03125C0.5 7.59375 0.8125 7.25 1.25 7.25H6.25V2.25C6.25 1.84375 6.5625 1.53125 7 1.53125C7.40625 1.53125 7.75 1.84375 7.75 2.25V7.25H12.75C13.1562 7.25 13.5 7.59375 13.5 8Z" fill="currentColor"/>
        // </svg>
        // }
        leftIcon={PlusIcon}
      >
        New draft
      </Button>
    );
  };

  const renderRichTextOptions = (props = {}) => {
    let { mb = 2 } = props;
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        mb={mb}
        color={"gray.500"}
        // position={(isFullMode) ? "relative" : "absolute"}
        // top={"20px"}
        maxW={"390px"}
      >
        <IconButton
          aria-label="make text bold"
          zIndex="9"
          // variant="unstyled"
          variant="ghost"
          size="sm"
          icon={<Icon as={MdFormatBold} color="gray.500" fontSize="16px" />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            editText(
              replaceSelectedWithUnicode(refTextArea.current, "sansBold")
            );
          }}
        />
        <IconButton
          aria-label="make text italic"
          zIndex="9"
          // variant="unstyled"
          variant="ghost"
          size="sm"
          icon={<CgFormatItalic color="gray.500" fontSize="16px" />}
          onClick={(e) => {
            //sansItalic
            e.preventDefault();
            e.stopPropagation();
            editText(
              replaceSelectedWithUnicode(refTextArea.current, "sansItalic")
            );
          }}
        />
        <IconButton
          aria-label="make text italic bold"
          zIndex="9"
          // variant="unstyled"
          variant="ghost"
          size="sm"
          icon={<MdFormatItalic color="gray.500" fontSize="16px" />}
          onClick={(e) => {
            //sansBoldItalic
            e.preventDefault();
            e.stopPropagation();
            editText(
              replaceSelectedWithUnicode(refTextArea.current, "sansBoldItalic")
            );
          }}
        />
        <IconButton
          aria-label="make text underline"
          zIndex="9"
          // variant="unstyled"
          variant="ghost"
          size="sm"
          icon={<MdFormatUnderlined color="gray.500" fontSize="16px" />}
          onClick={(e) => {
            // "", {append: "͟",}
            e.preventDefault();
            e.stopPropagation();
            editText(
              replaceSelectedWithUnicode(refTextArea.current, "", {
                append: "\u035f",
              })
            );
          }}
        />
        <IconButton
          aria-label="make text strike through"
          zIndex="9"
          // variant="unstyled"
          variant="ghost"
          size="sm"
          icon={<MdStrikethroughS color="gray.500" fontSize="16px" />}
          onClick={(e) => {
            //"", { append: "̶",}
            e.preventDefault();
            e.stopPropagation();
            editText(
              replaceSelectedWithUnicode(refTextArea.current, "", {
                append: "\u0336",
              })
            );
          }}
        />
        <IconButton
          aria-label="make text superscript"
          zIndex="9"
          // variant="unstyled"
          variant="ghost"
          size="sm"
          icon={<MdSuperscript color="gray.500" fontSize="16px" />}
          onClick={(e) => {
            //superscript
            e.preventDefault();
            e.stopPropagation();
            editText(
              replaceSelectedWithUnicode(refTextArea.current, "superscript")
            );
          }}
        />
        <IconButton
          aria-label="make text subscript"
          zIndex="9"
          // variant="unstyled"
          variant="ghost"
          size="sm"
          icon={<MdSubscript color="gray.500" fontSize="16px" />}
          onClick={(e) => {
            //subscript
            e.preventDefault();
            e.stopPropagation();
            editText(
              replaceSelectedWithUnicode(refTextArea.current, "subscript")
            );
          }}
        />
        <IconButton
          aria-label="make text monospace"
          zIndex="9"
          // variant="unstyled"
          variant="ghost"
          size="sm"
          icon={<MdCode color="gray.500" fontSize="16px" />}
          onClick={(e) => {
            //monospace
            e.preventDefault();
            e.stopPropagation();
            editText(
              replaceSelectedWithUnicode(refTextArea.current, "monospace")
            );
          }}
        />
        <Select
          placeholder="Font"
          variant="flushed"
          size="sm"
          width={"100px"}
          display="inline-block"
          border="0px"
          textAlign="center"
          onChange={(e) => {
            editText(
              replaceSelectedWithUnicode(refTextArea.current, e.target.value)
            );
          }}
        >
          <option value="normal">Normal Text</option>
          <option value="fullwidth">Fullwidth Text</option>
          <option value="fraktur">Fraktur</option>
          <option value="boldFraktur">Fraktur Bold</option>
          <option value="doubleStruck">Double Struck</option>
          <option value="script">Cursive Script</option>
          <option value="circled">Circled</option>
          <option value="squared">Squared</option>
          <option value="squaredNegative">Squared Inverse</option>
          <option value="inverted">Inverted</option>
          <option value="mirrored">Mirrored</option>
        </Select>
      </Box>
    );
  };

  const iconSize = isFullMode ? "16px" : "1.3em";

  const renderAIReWriteIcon = () => {
    return (
      <Tooltip
        placement={isFullMode ? "top" : "right"}
        hasArrow
        label="The AI will try to re-write whatever you've written so far."
      >
        <IconButton
          aria-label="re-write the tweet"
          isLoading={isReWriting}
          variant="actionAI"
          mr={1}
          // zIndex="9"
          size="sm"
          // icon={<HiOutlineLightningBolt size={iconSize} />}
          icon={Bolt}
          onClick={() => {
            if (isFullMode) handleAIReWriteClick();
            else onOpenRewrite();
          }}
        />
      </Tooltip>
    );
  };

  const renderAIContinueIcon = () => {
    return (
      <Tooltip
        placement={isFullMode ? "top" : "right"}
        hasArrow
        label="The AI will try to continue where you stopped. The more you have already written, the better it should be."
      >
        <IconButton
          aria-label="generate next"
          isLoading={isGenerating}
          variant="actionAI"
          // mr={2}
          // zIndex="9"
          size="sm"
          icon={FastForward}
          onClick={handleAIGenerationIconClick}
        />
      </Tooltip>
    );
  };

  const renderTextArea = () => {
    let containerStyles = {};
    if (mode === "engage") {
      containerStyles = {
        pb: 2,
        borderRadius: radius.lg,
        border: "1px solid",
        borderColor: colorNames.border.lightMode.light,
        _hover: {
          borderColor: colorNames.border.lightMode.hover,
        },
        _dark: {
          borderColor: "border.darkMode.light",
          _hover: {
            borderColor: colorNames.border.darkMode.hover,
          },
        },
        bg: engageContainerBg,
      };
    }
    return (
      <Box {...containerStyles}>
        <Textarea
          {...dragProps}
          {...{
            // from textArea theme which doesn't seem to get applied
            borderRadius: radius.lg, // not being applied. to be investigated
            border: isFullMode || mode === "engage" ? "none" : "1px solid",
            borderColor: colorNames.border.lightMode.light,
            // color: "text.lightMode.standard",
            // textStyle: "body.medium.standard",
            _hover: {
              borderColor: isFullMode
                ? "none"
                : colorNames.border.lightMode.hover,
            },
            _dark: {
              borderColor: "border.darkMode.light",
              color: "text.darkMode.standard",
              _hover: {
                borderColor: colorNames.border.darkMode.hover,
              },
            },
          }}
          variant={isFullMode ? "unstyled" : "taplio"}
          ref={refTextArea}
          transition="height none"
          id="tweet-area"
          value={textState?.text}
          maxH={
            isShowThreadTweetPreview &&
            browser?.getBrowserName()?.includes("Safari")
              ? "calc(100vh - 500px)"
              : "calc(100vh - 250px)"
          }
          dir={getAccount(session)?.isRtl ? "rtl" : "ltr"}
          // borderColor={"gray.200"}
          pr={isFullMode ? 5 : 2}
          pb={10}
          //@ts-ignore
          // ref={firstField}
          onKeyDown={handleKeyDown}
          onKeyUp={(e) => {
            if (e.keyCode === 27) {
              setIsSuggestionOpen(false);
              setSuggestions([]);
            }
          }}
          onChange={(e) => {
            if (!disableSuggestion) debounced(e);

            // console.log("onChangeTriggered: ", e.target.value)
            if (!needReset) {
              editText(e.target.value);
            }
          }}
          h={"200px"}
          style={{ minHeight: "100%" }}
          resize={"vertical"}
          isDisabled={
            isDisabled || (!isAuthenticated && !tweetContext.isIncognitoMode)
          }
          // _focus={{boxShadow: 'none'}}
          // border="none"
          placeholder={mode == "tweet" ? placeHolder : "Write here"}
          onPaste={(e) => {
            // console.log("paste");
            // console.log(e);
            if (e?.clipboardData?.files?.length > 0) {
              // Stop text paste
              e.preventDefault();
              onFileUpload(e.clipboardData.files);
            }
          }}
          textStyle={"body.medium.standard"}
          fontSize="14px"
          fontFamily="'Source Sans Pro', Helvetica, sans-serif, serif"
        />
        {mode === "engage" ? (
          <AIReplyButtonsMemoed
            tweetText={tweet?.full_text}
            onNewText={(newText) =>
              editText((textState?.text ? textState?.text : "") + newText)
            }
          />
        ) : null}
      </Box>
    );
  };

  const selectTweetInThread = (text, tweet) => {
    // console.log('selectTweetInThread text:', text, tweet);
    let cursorPos = textState?.text?.indexOf(text);
    if (refTextArea.current) {
      refTextArea.current.setSelectionRange(cursorPos, cursorPos);
      refTextArea.current.focus();
      refTextArea.current.setSelectionRange(
        cursorPos,
        cursorPos + text?.length
      );
    }
  };

  const handleImageClick = (id: string) => {
    const isNotImage = id.includes("vid-") || id.includes("gif-");
    if (isNotImage) {
      setSelectedImageId("");
      return;
    }

    setSelectedImageId(id);
    onOpenImageEdit();
  };

  const handleSendPrompt = async (prompt: any) => {
    if (tweetContext?.refComposer?.current?.textState()?.text?.length < 2) {
      toast.error("Please write a tweet first");
      return;
    }

    prompt.systemPrompt =
      (prompt?.systemPrompt || "") +
      `\n- write and generate the user's post in ${
        getAccount(session)?.languagePreferred || "English"
      }. DO NOT USE ANY OTHER LANGUAGE. ONLY ${
        getAccount(session)?.languagePreferred || "English"
      }.`;
    await chatContext?.chatAssistRef?.current?.setMessages([]);
    chatContext?.onOpen();
    chatContext?.chatAssistRef?.current?.sendMessage(
      prompt?.userPrompt,
      prompt?.systemPrompt
    );
  };

  const renderProbabilityIndicator = (
    proba_outperforming,
    confidence,
    showText = false
  ) => {
    // console.log("proba_outperforming: " + proba_outperforming);

    if (!proba_outperforming) {
      return <></>;
    }

    let textProps = {
      color: "gray.400",
      ml: 2,
      fontSize: "10px",
      fontWeight: "600",
      h: "12px",
    };

    let icon;
    let text;

    if (proba_outperforming == -1) {
      icon = (
        <Box
          {...gradientProps}
          bg={`linear-gradient(white, white) padding-box, linear-gradient(135deg, ${theme.colors.gray[400]}, ${theme.colors.gray[500]}) border-box`}
        ></Box>
      );
      text = "no prediction";
    } else if (confidence < 0.1) {
      icon = (
        <Box
          {...gradientProps}
          bg={`linear-gradient(white, white) padding-box, linear-gradient(135deg, ${theme.colors.gray[400]}, ${theme.colors.gray[500]}) border-box`}
        ></Box>
      );
      text = "Off Topic";
    } else if (proba_outperforming < 0.6) {
      icon = (
        <Box
          {...gradientProps}
          bg={`linear-gradient(white, white) padding-box, linear-gradient(135deg, ${theme.colors.blue[400]}, ${theme.colors.blue[500]}) border-box`}
        ></Box>
      );
      text = "OK";
    } else if (proba_outperforming < 0.75) {
      icon = (
        <Box
          {...gradientProps}
          bg={`linear-gradient(white, white) padding-box, linear-gradient(135deg, ${theme.colors.green[400]}, ${theme.colors.green[500]}) border-box`}
        ></Box>
      );
      text = "Above Average";
    } else {
      return (
        <Flex alignItems="center" position="relative">
          <Icon
            as={BsCheck}
            fontSize="lg"
            color="white"
            position="absolute"
            left="0px"
            top="0px"
          />
          <Box {...gradientProps} border="9px solid"></Box>
          {showText && <Text {...textProps}>High Potential</Text>}
        </Flex>
      );
    }

    return (
      <Flex alignItems="center" position="relative">
        {icon}
        {showText && <Text {...textProps}>{text}</Text>}
      </Flex>
    );
  };

  // console.log("browser: " + browser?.getBrowserName());
  React.useEffect(() => {
    const hasMedia = textState?.text?.includes("[img:");
    const showPreviewMobile = tweetContext.isPreviewOpen && isTooSmallForThread;
    const showPreviewDesktop =
      !disableThread &&
      (textState?.threadTweets?.length > 1 ||
        (alwaysShowSidebar && textState?.text?.length > 0) ||
        isFullMode ||
        hasMedia) &&
      tweetContext.isOpen &&
      mode == "tweet";
    let isShowThreadTweetPreview = isTooSmallForThread
      ? showPreviewMobile
      : showPreviewDesktop;

    setIsShowThreadTweetPreview(
      isTooSmallForThread ? showPreviewMobile : showPreviewDesktop
    );
  }, [
    textState,
    tweetContext.isPreviewOpen,
    tweetContext.isOpen,
    disableThread,
    alwaysShowSidebar,
    isFullMode,
    mode,
    isTooSmallForThread,
  ]);
  let dragProps = {};
  if (mode == "tweet") dragProps = { ...getRootProps({ style }) };

  // console.log('tweetContext.isOpen:', tweetContext.isOpen);
  // console.log('isShowThreadTweetPreview:', isShowThreadTweetPreview);
  // console.log('showPreviewMobile:', showPreviewMobile);
  // console.log('showPreviewDesktop:', showPreviewDesktop);
  // console.log('isShowThreadTweetPreview:', isShowThreadTweetPreview)

  let colorPopularityOutperforming = "black";
  if (popularity?.confidence < 0.1) colorPopularityOutperforming = "gray";
  else if (popularity?.proba_outperforming < 0.6)
    colorPopularityOutperforming = "blue.400";
  // else if (popularity?.proba_outperforming < 0.7)
  //     colorPopularityOutperforming = "green.400";
  else colorPopularityOutperforming = "green.500";

  const lgFrom = "#D50071"; // theme.colors.red[400];
  const lgTo = "#E1C800"; // theme.colors.blue[400];
  const bgColor = theme.colors.white;
  let gradientProps = {
    w: "18px",
    h: "18px",
    color: "gray.900",
    border: "7px solid",
    borderColor: "transparent",
    borderRadius: "full",
    background: `linear-gradient(${bgColor}, ${bgColor}) padding-box, linear-gradient(135deg, ${lgFrom}, ${lgTo}) border-box`,
    _active: {
      transform: "scale(1.04)",
      opacity: "0.7",
    },
    _focus: {
      boxShadow: "none",
    },
  };

  const suggestionBoxWidth = 260;
  const textAreaWidth = refTextArea?.current?.clientWidth ?? 0;

  const suggestionsUI = isSuggestionsOpen && suggestions?.length > 0 && (
    <Box
      position="absolute"
      zIndex="popover"
      maxH="44"
      backgroundColor={backgroundColor}
      overflow="auto"
      border="1px"
      borderRadius="md"
      boxShadow="md"
      width="260px"
      left={
        (position.left + suggestionBoxWidth > textAreaWidth
          ? textAreaWidth - suggestionBoxWidth
          : position.left) + "px"
      }
      borderColor={borderColor}
      padding="1"
      top={position.top + 24 + "px"}
      tabIndex={1}
      ref={suggestionPopupRef}
    >
      <Suggestions suggestions={suggestions} onSelect={handleSelect} />
    </Box>
  );

  // console.log("composer", textState);
  // console.log(isFullMode + " - text: " + textState.text);

  const [isOpenAiReWrite, isOpenAiReWriteSet] = useState(false);
  return (
    <Box h={isFullMode ? "100%" : "unset"}>
      {
        // mode == "tweet" && !isTooSmallForThread && isShowPrediction && (
        //     <VStack
        //         position="fixed"
        //         right={isShowThreadTweetPreview ? "800px" : "420px"} //transform="translateX(-30%)"
        //         top={"20px"}
        //         w={"350px"}
        //         // h={"200px"}
        //         // overflow="hidden scroll"
        //         boxShadow="rgba(100, 100, 111, 0.35) 0px 2px 19px 0px;"
        //         rounded="lg"
        //         flexDir="column"
        //         zIndex={21}
        //         p={6}
        //         spacing={0}
        //         alignItems="flex-start"
        //         bg={gifContainerBg}
        //     // overflow="scroll"
        //     >
        //         <Flex w="100%" justifyContent="space-between" alignItems="flex-start">
        //             <Text textStyle="h2.bold.standard">TweetPredict™ Analysis</Text>
        //             <CloseButton mt="-5px" mr="-5px" onClick={(e) => setIsShowPrediction(false)} />
        //         </Flex>
        //         {/* <Checkbox
        //             mt={0}
        //             size="sm"
        //             isChecked={alwaysShowPrediction}
        //             onChange={(e) => handleSetShowPrediction()}
        //         >
        //             Always show this
        //         </Checkbox> */}
        //         {/* <Text>prediction: {isLoadingPop ? <Spinner size="xs" color="gray.400" /> : popularity?.prediction}</Text> */}
        //         <Flex w="100%" pt={2}>
        //             {/* <Tooltip label="Tells you how consistent this tweet is compared to how you normally tweet. 100% means it is exactly how you normally tweet.">
        //                 <Flex flexDir="column" w="100%" alignItems="flex-start" justifyContent="space-between">
        //                     <Text fontSize="xl" fontWeight="800" color={popularity?.confidence < 0.5 ? "orange.400" : textColor} >
        //                         {isLoadingPop ? <Spinner size="xs" color="gray.400" /> : Math.floor(popularity?.confidence * 100) + "%"}
        //                     </Text>
        //                     <Text fontSize="sm" fontWeight="600" w="110px">Account consistency</Text>
        //                 </Flex>
        //             </Tooltip> */}
        //             <Tooltip label="Gives you the probability that this tweet will get more engagement than your average.">
        //                 <Flex flexDir="column" minW="125px" alignItems="flex-start" justifyContent="space-between">
        //                     <Text fontSize="3xl" fontWeight="800" color={colorPopularityOutperforming}>
        //                         {isLoadingPop ? <Spinner size="xs" color="gray.400" /> : (popularity?.proba_outperforming == -1 ? "NA" : Math.floor(popularity?.proba_outperforming * 100) + "%")}
        //                     </Text>
        //                     <Text textStyle="h4.bold.standard" >Outperforming probability </Text>
        //                     {/* ({Math.floor(popularity?.confidence * 100) + "%"}) */}
        //                 </Flex>
        //             </Tooltip>
        //             {
        //                 popularity?.confidence < 0.1 && popularity?.confidence >= 0 && (
        //                     <Text w="100%" fontSize="xs" color="red" fontWeight="600">Warning: this tweet seems inconsistent with what you usually talk about, making it hard to predict performance.</Text>
        //                 )
        //             }
        //             {
        //                 popularity?.proba_outperforming == -1 && (
        //                     <Text w="100%" fontSize="xs" color="red" fontWeight="600">Predictions are failing, probably because you don't have enough published tweets</Text>
        //                 )
        //             }
        //         </Flex>
        //         <Flex pt={5}>
        //             <Button variant="outline"
        //                 onClick={(e) => {
        //                     handleSendPrompt(prompts[0]);
        //                 }}
        //             >
        //                 Get improvement suggestions
        //             </Button>
        //         </Flex>
        //         <Flex flexDir="column" pt={5}>
        //             {/* <Text as="i" >The lower the consistency, the lower the outperforming probability is reliable. </Text> */}
        //             <Accordion allowMultiple>
        //                 <AccordionItem border="none">
        //                     {/* <h2> */}
        //                     <AccordionButton as={Button} p={0} justifyContent="flex-start" variant="tertiaryNoBackground" fontSize="sm">
        //                         {/* <Text mr={1} fontSize="sm" textStyle="body.medium.light"> */}
        //                         {"How to interpret results "}
        //                         {/* </Text> */}
        //                         <AccordionIcon fontSize="md" />
        //                     </AccordionButton>
        //                     {/* </h2> */}
        //                     <AccordionPanel p={0} display="flex" flexDir="column" fontSize="sm" textStyle="body.medium.light">
        //                         {/* <Text mt={4} >Low account consistency means the tweet seems off-topic compared to what you usually talk about. Low consistency makes the analysis performance much less reliable.</Text> */}
        //                         <Text mt={4} >The higher the "outperforming probability", the more likely it is your tweet will overperform and get significantly more engagement than usual.</Text>
        //                         <Text mt={4} fontWeight="600" >Scale</Text>
        //                         <Flex mt={2} alignItems="center">
        //                             {/* <Icon as={FaRegDotCircle} fontSize="md" color="gray" /> */}
        //                             {renderProbabilityIndicator(0.01, 0.01)}
        //                             <Text ml={2} fontWeight="600">Inconsistent - hard to predict</Text>
        //                         </Flex>
        //                         <Flex mt={2} alignItems="center">
        //                             {/* <Icon as={FaRegDotCircle} fontSize="md" color="blue.400" /> */}
        //                             {renderProbabilityIndicator(0.3, 1)}
        //                             <Text ml={2} fontWeight="600">Ok - average performance</Text>
        //                         </Flex>
        //                         <Flex mt={2} alignItems="center">
        //                             {/* <Icon as={FaRegDotCircle} fontSize="md" color="green.500" /> */}
        //                             {renderProbabilityIndicator(0.6, 1)}
        //                             <Text ml={2} fontWeight="600">Good - above average performance</Text>
        //                         </Flex>
        //                         <Flex mt={2} alignItems="center" position="relative">
        //                             {/* <Icon as={BsCheckCircleFill} fontSize="md" color="green.400" /> */}
        //                             {renderProbabilityIndicator(1, 1)}
        //                             <Text ml={2} fontWeight="600">Excellent - high potential tweet</Text>
        //                         </Flex>
        //                         <Text mt={5} fontWeight="600">Tweet like if you were someone else</Text>
        //                         <Text mt={2}>If you have a low number of tweets or engagement, the AI can struggle predicting anything. Input a Twitter account tweeting about the same topics and writing with the same style to improve results. </Text>
        //                         <Flex mt={4}>
        //                             <InputGroup rounded="lg" >
        //                                 <InputLeftAddon rounded="md" children='@' />
        //                                 <Input rounded="md" placeholder='tibo_maker' value={accountForPrediction} onChange={(e) => setAccountForPrediction(e.target.value)} />
        //                             </InputGroup>
        //                             <Button
        //                                 variant="secondary"
        //                                 // size="sm"
        //                                 isLoading={isLoadingAccountPrediction} ml={2} onClick={async (e) => {
        //                                     setIsLoadingAccountPrediction(true);
        //                                     if (!accountForPrediction) {
        //                                         await updateUser(session, { accountForPrediction: "", accountIdForPrediction: "" }, true);
        //                                         return;
        //                                     }
        //                                     let dataToSend = {
        //                                         twUserName: accountForPrediction,
        //                                         thCustomClientId: getAccount(session)?.thCustomClientId,
        //                                         thCustomClientSecret: getAccount(session)?.thCustomClientSecret,
        //                                         thWriteAccessToken: getAccount(session).thWriteAccessToken,
        //                                         thWriteSecretToken: getAccount(session).thWriteSecretToken,
        //                                         thApp: getAccount(session).thApp,
        //                                     };
        //                                     let data = await searchUsers(session, dataToSend);
        //                                     if (data?.users?.length) {
        //                                         let accountIdForPrediction = data?.users[0].id_str;
        //                                         await updateUser(session, { accountForPrediction, accountIdForPrediction }, true);
        //                                     }
        //                                     else {
        //                                         toast.error("User not found");
        //                                     }
        //                                     setIsLoadingAccountPrediction(false);
        //                                 }}
        //                                 leftIcon={SaveIcon}
        //                             >
        //                                 Save
        //                             </Button>
        //                         </Flex>
        //                     </AccordionPanel>
        //                 </AccordionItem>
        //             </Accordion>
        //         </Flex>
        //     </VStack>
        // )
      }

      {isShowThreadTweetPreview && (
        <ThreadTweetPreviewSidebar
          selectTweetInThread={selectTweetInThread}
          textState={textState}
          alwaysShowSidebar={alwaysShowSidebar}
          handleSetShowSidebar={handleSetShowSidebar}
          mode={mode}
          disableThread={disableThread}
          disablePreview={disablePreview}
          id={refId?.current}
        />
      )}

      {!disableHeader && (
        <>
          {isFullMode ? (
            <Box
              mb={4}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              textStyle={textStyle["h4.bold.standard"]}
              // textStyle={textStyle['body.medium.primary']}
            >
              <Flex alignItems={"center"}>
                <Flex justifyContent="flex-start" alignItems="center" mr={3}>
                  {/* <FiEdit3 size='1.2em' color='#272c38' /> */}
                  <EditIconComponent {...{ width: 16, height: 16 }} />
                  <Text
                    ml={2}
                    // fontSize={'md'}
                    // fontWeight={700}
                  >
                    Content
                  </Text>
                </Flex>
                {renderNewDraftButton()}
              </Flex>
              {createDraft &&
                (isSaving ? (
                  <Box ml={1}>
                    <Spinner
                      size="xs"
                      {...{
                        color: colors.text.lightMode.inactive,
                        _dark: {
                          color: colors.text.darkMode.inactive,
                        },
                      }}
                    />
                  </Box>
                ) : (
                  <Text
                    align="right"
                    // color="gray.400"
                    // fontSize="sm"
                    // as="i"
                    textStyle={textStyle["body.medium.light"]}
                    {...{
                      color: colors.text.lightMode.inactive,
                      _dark: {
                        color: colors.text.darkMode.inactive,
                      },
                    }}
                  >
                    Saved as draft @{moment().format("HH:mm")}
                  </Text>
                ))}
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              {/* <FormLabel htmlFor="desc">Your tweet</FormLabel> */}
              <Flex alignItems="center">
                <Text
                  fontWeight="700"
                  // textStyle={textStyle["body.medium.light"]}
                  textStyle={textStyle["body.bold.standard"]}
                >
                  Your content{" "}
                </Text>

                {
                  // session?.user?.data?.isBeta && popularity?.prediction !== undefined && (
                  // popularity?.prediction !== undefined && popularity?.prediction !== 0 && (
                  //     <Flex ml={4}
                  //         w="110px"
                  //         cursor={'pointer'}
                  //         _hover={{ opacity: "0.5" }}
                  //         onClick={() => setIsShowPrediction(!isShowPrediction)}
                  //     >
                  //         {isLoadingPop ? <Spinner size="sm" color="gray.400" /> : renderProbabilityIndicator(popularity?.proba_outperforming, popularity?.confidence, true)}
                  //     </Flex>
                  // )
                }
              </Flex>
              {renderNewDraftButton()}
            </Box>
          )}
        </>
      )}
      <Box h={"85%"} position="relative">
        {mode == "tweet" ? (
          isMobile ? (
            <>
              {isFullMode ? (
                <Center w="100%" h="100%">
                  <Box w="100%" h="100%" maxW="500px" position="relative">
                    {renderTextArea()}
                    {suggestionsUI}
                  </Box>
                </Center>
              ) : (
                <>
                  {renderTextArea()}
                  {suggestionsUI}
                </>
              )}
            </>
          ) : (
            <>
              {isFullMode ? (
                <Center w="100%" h="100%">
                  <Box w="100%" h="100%" maxW="500px" position="relative">
                    {renderTextArea()}
                    {suggestionsUI}
                  </Box>
                </Center>
              ) : (
                <>
                  {renderTextArea()}
                  {suggestionsUI}
                </>
              )}
            </>
          )
        ) : (
          <>
            {renderTextArea()}
            {suggestionsUI}
          </>
        )}
      </Box>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={isFullMode ? "center" : "space-between"}
        minHeight={10}
      >
        <Flex justifyContent="flex-start" alignItems="center" w="129px">
          {(!textState?.threadTweets ||
            textState?.threadTweets?.length <= 1 ||
            mode == "taplio-push") &&
            !["autoDm", "dm"].includes(mode) &&
            !isFullMode &&
            (mode != "taplio-push" || nbChar > 2500) && (
              <Flex
                // align="right"
                flexWrap="nowrap"
                color={nbChar > postCharMax ? "red" : "gray.400"}
                fontWeight={nbChar > postCharMax ? "bold" : "regular"}
                fontSize="xs"
                // mr={2}
              >
                {nbChar} / {postCharMax}
              </Flex>
            )}
          {createDraft &&
            !isFullMode &&
            (isSaving ? (
              <Box ml={3} fontSize="xs">
                <Spinner size="xs" color="gray.400" />
              </Box>
            ) : (
              <Flex
                // align="right"
                alignItems="center"
                gap={1}
                color="gray.400"
                fontSize="xs"
                ml={2}
                // as="i"
              >
                saved
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.75 3.21875C15.0625 3.53125 15.0625 4 14.75 4.28125L6.5 12.5312C6.21875 12.8438 5.75 12.8438 5.46875 12.5312L1.21875 8.28125C0.90625 8 0.90625 7.53125 1.21875 7.21875C1.5 6.9375 1.96875 6.9375 2.25 7.21875L6 10.9688L13.7188 3.21875C14 2.9375 14.4688 2.9375 14.75 3.21875Z"
                    fill="currentColor"
                  />
                </svg>
              </Flex>
            ))}
        </Flex>
        <Box
          position={isFullMode ? "absolute" : "relative"}
          mt={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          bottom={isFullMode ? "4px" : "0px"}
          left={isFullMode ? "50%" : "0px"}
          transform={isFullMode ? "translateX(-50%)" : ""}
          // maxW="300px"
          // overflow="scroll"
        >
          {!disableReWrite &&
          !isFullMode &&
          isAllowed(session?.user, "chat") ? (
            <>
              <Tooltip
                placement="right"
                label="Use AI to improve or continue your tweet."
                hasArrow
              >
                <Button
                  w="48px"
                  onClick={() => isOpenAiReWriteSet(!isOpenAiReWrite)}
                  as={IconButton}
                  size="sm"
                  variant="actionAI"
                  {...{
                    _hover: {
                      color: colors.ai[700],
                      bg: colors.ai[50],
                    },
                    _dark: {
                      color: "text.darkMode.standard",
                      bg: "transparent",
                      _hover: {
                        color: colors.ai[200],
                        bg: hexToHexa(colors.ai[900], 0.2),
                      },
                      _active: {
                        color: colors.ai[300],
                        bg: hexToHexa(colors.ai[900], 0.4),
                      },
                    },
                  }}
                  icon={
                    <>
                      <Flex mx="2">
                        <AiButtonSVGIcon />
                        {/* <ChevronDownIcon/> */}
                        <motion.div
                          animate={{
                            rotate: isOpenAiReWrite ? 180 : 0,
                          }}
                          transition={{
                            type: "spring",
                            duration: 0.5,
                            bounce: 0,
                          }}
                        >
                          <Box ml=".5" opacity={0.8} color={"#BB66A5;"}>
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.6875 7.71875L6.71875 11.7188C6.5 11.9062 6.25 12 6 12C5.71875 12 5.46875 11.9062 5.28125 11.7188L1.3125 7.71875C1 7.4375 0.90625 7 1.0625 6.625C1.21875 6.25 1.59375 6 2 6H9.96875C10.375 6 10.7188 6.25 10.875 6.625C11.0312 7 10.9688 7.4375 10.6875 7.71875Z"
                                fill="currentColor"
                              />
                            </svg>
                          </Box>
                        </motion.div>
                      </Flex>
                    </>
                  }
                />
              </Tooltip>

              {/* <Collapse in={isOpenAiReWrite} animateOpacity>
                                    <Flex
                                        alignItems="center"
                                        gap={2}
                                        px="6"
                                        pt="6"
                                        pb="2"
                                        border="none"
                                        textStyle={textStyle["body.bold.standard"]}
                                    >
                                        Improve your tweet with AI
                                    </Flex>
                                    <Grid gridTemplateColumns={['repeat(1, 160px)', 'repeat(2, auto)']} rowGap="1" columnGap="0" p="2">
                                        {prompts.map(({ icon: Icon, ...prompt }) => (
                                            <Button
                                                key={prompt.title}
                                                variant="actionAI"
                                                leftIcon={<Icon />}
                                                pl="4"
                                                w="100%"
                                                justifyContent="left"
                                                onClick={() => handleSendPrompt(prompt)}
                                            >
                                                {prompt.title}
                                            </Button>
                                        ))}
                                    </Grid>
                                </Collapse> */}

              {/* <Menu variant="AI" isLazy>
                            <Tooltip
                                placement="right"
                                label="Use AI to improve or continue your tweet."
                                hasArrow
                            >
                                <MenuButton
                                    as={IconButton}
                                    size="sm"
                                    variant="actionAI"
                                    {...{
                                        _hover: {
                                            color: colors.ai[700],
                                            bg: colors.ai[50],
                                        },
                                        _dark: {
                                            color: "text.darkMode.standard",
                                            bg: "transparent",
                                            _hover: {
                                                color: colors.ai[200],
                                                bg: hexToHexa(colors.ai[900], 0.2),
                                            },
                                            _active: {
                                                color: colors.ai[300],
                                                bg:hexToHexa(colors.ai[900], 0.4),
                                            },
                                        },
                                    }}
                                    icon={
                                        <>
                                        <Flex mx="2">
                                        <AiButtonSVGIcon />
                                            <Box ml=".5"  opacity={.8} color={"#BB66A5;"}>
                                            <svg width="12" height="12" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6875 7.71875L6.71875 11.7188C6.5 11.9062 6.25 12 6 12C5.71875 12 5.46875 11.9062 5.28125 11.7188L1.3125 7.71875C1 7.4375 0.90625 7 1.0625 6.625C1.21875 6.25 1.59375 6 2 6H9.96875C10.375 6 10.7188 6.25 10.875 6.625C11.0312 7 10.9688 7.4375 10.6875 7.71875Z" fill="currentColor"/>
                                            </svg>
                                            </Box>
                                        </Flex>
                                        </>

                                    }
                                />
                            </Tooltip>
                            <MenuList>
                                <Flex
                                    alignItems="center"
                                    gap={2}
                                    px="6"
                                    pt="6"
                                    pb="2"
                                    border="none"
                                    textStyle={textStyle["body.bold.standard"]}
                                >
                                    Improve your tweet with AI
                                </Flex>
                                <Grid gridTemplateColumns={['repeat(1, 160px)', 'repeat(2, auto)']} rowGap="1" columnGap="0" p="2">
                                    {prompts.map(({ icon: Icon, ...prompt }) => (
                                        <Button
                                            key={prompt.title}
                                            variant="actionAI"
                                            leftIcon={<Icon />}
                                            pl="4"
                                            w="100%"
                                            justifyContent="left"
                                            onClick={() => handleSendPrompt(prompt)}
                                            >
                                            {prompt.title}
                                        </Button>
                                    ))}
                                </Grid>
                            </MenuList>
                            </Menu> */}
            </>
          ) : // <Tooltip placement={isFullMode? 'top' : "right"} hasArrow label="The AI will try to re-write whatever you've written so far.">
          //     <IconButton
          //         aria-label="re-write the tweet"
          //         isLoading={isReWriting}
          //         variant="tertiary"
          //         mr={1}
          //         // zIndex="9"
          //         size="sm"
          //         icon={<HiOutlineLightningBolt color="gray" size={iconSize} />}
          //         onClick={() => {
          //             if (isFullMode)
          //                 handleAIReWriteClick();
          //             else
          //                 onOpenRewrite();
          //         }}
          //     />
          // </Tooltip>
          null}
          {!disableSnippets && (
            <IconButton
              aria-label="open snippets"
              // zIndex="9"
              variant="action"
              size="composer"
              // mr={2}
              icon={
                <Icon viewBox="0 0 16 16">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 14.5H9C9.25 14.5 9.5 14.2812 9.5 14V12H11V14C11 15.125 10.0938 16 9 16H2C0.875 16 0 15.125 0 14V7C0 5.90625 0.875 5 2 5H4V6.5H2C1.71875 6.5 1.5 6.75 1.5 7V14C1.5 14.2812 1.71875 14.5 2 14.5ZM5 2C5 0.90625 5.875 0 7 0H14C15.0938 0 16 0.90625 16 2V9C16 10.125 15.0938 11 14 11H7C5.875 11 5 10.125 5 9V2ZM7 9.5H14C14.25 9.5 14.5 9.28125 14.5 9V2C14.5 1.75 14.25 1.5 14 1.5H7C6.71875 1.5 6.5 1.75 6.5 2V9C6.5 9.28125 6.71875 9.5 7 9.5Z"
                      fill="currentColor"
                    />
                  </svg>
                </Icon>
              }
              onClick={() => {
                setIsSnippetsOpen(!isSnippetsOpen);
              }}
            />
          )}
          {isSnippetsOpen && (
            <Snippets
              isSnippetsOpen={isSnippetsOpen}
              setIsSnippetsOpen={setIsSnippetsOpen}
              pushText={pushText}
            />
          )}
          {/* {
                        !disableAiContinue && !isFullMode && renderAIContinueIcon()
                    } */}

          {!disableRichText && (
            <Tooltip hasArrow label="Add rich text">
              <IconButton
                aria-label="add rich text"
                zIndex="9"
                variant="action"
                size="composer"
                size="sm"
                icon={
                  <Icon viewBox="0 0 14 14">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 0.75V3.25C14 3.6875 13.6562 4 13.25 4C12.8125 4 12.5 3.6875 12.5 3.25V1.5H7.75V12.5H9.25C9.65625 12.5 10 12.8438 10 13.25C10 13.6875 9.65625 14 9.25 14H4.75C4.3125 14 4 13.6875 4 13.25C4 12.8438 4.3125 12.5 4.75 12.5H6.25V1.5H1.5V3.25C1.5 3.6875 1.15625 4 0.75 4C0.3125 4 0 3.6875 0 3.25V0.75C0 0.34375 0.3125 0 0.75 0H13.25C13.6562 0 14 0.34375 14 0.75Z"
                        fill="currentColor"
                      />
                    </svg>
                  </Icon>
                }
                onClick={() => {
                  keepRefreshSelection(refTextArea.current);
                  setIsRichTextOpen(!isRichTextOpen);
                }}
              />
            </Tooltip>
          )}
          {!disableImage && (
            <IconButton
              aria-label="add image"
              isLoading={isSavingImage}
              isDisabled={isSavingImage || isSavingGif}
              variant="action"
              size="composer"
              icon={<HiOutlineDocumentAdd size={18} />}
              onClick={() => {
                setTabIndex(0);
                setSelectedMedias([]);
                onOpenImageUpload();
              }}
            />
          )}
          <IconButton
            ref={refTriggerEmoji}
            aria-label="add emoji"
            // zIndex="9"
            // variant="unstyled"
            variant="action"
            size="composer"
            size="sm"
            // icon={<HiOutlineEmojiHappy color="gray" size={iconSize} />}
            icon={
              <Icon viewBox="0 0 16 16">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 11C9.15625 11 9.96875 10.4688 10.4375 9.96875C10.7188 9.65625 11.1875 9.625 11.5 9.90625C11.8125 10.1875 11.8125 10.6562 11.5312 10.9688C10.8438 11.7188 9.65625 12.5 8 12.5C6.3125 12.5 5.125 11.7188 4.4375 10.9688C4.15625 10.6562 4.15625 10.1875 4.46875 9.90625C4.78125 9.625 5.25 9.65625 5.53125 9.96875C6 10.4688 6.8125 11 8 11ZM6.5 6.5C6.5 7.0625 6.0625 7.5 5.5 7.5C4.9375 7.5 4.5 7.0625 4.5 6.5C4.5 5.96875 4.9375 5.5 5.5 5.5C6.0625 5.5 6.5 5.96875 6.5 6.5ZM9.5 6.5C9.5 5.96875 9.9375 5.5 10.5 5.5C11.0625 5.5 11.5 5.96875 11.5 6.5C11.5 7.0625 11.0625 7.5 10.5 7.5C9.9375 7.5 9.5 7.0625 9.5 6.5ZM16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8ZM8 1.5C4.40625 1.5 1.5 4.4375 1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5Z"
                    fill="currentColor"
                  />
                </svg>
              </Icon>
            }
            onClick={() => {
              setIsOpenEmoji(!isOpenEmoji);
            }}
          />
          {isOpenEmoji && (
            <Box
              position="absolute"
              right="0"
              top={isFullMode ? "-430px" : "30px"}
              w="340px"
              ref={refEmoji}
              zIndex="12"
              // layerStyle={layerStyle["bg.border.rounded"]}
            >
              <NimblePicker
                theme={nimblePickerTheme}
                set="twitter"
                data={data}
                sheetSize={64}
                autoFocus={true}
                // style={{ position: 'absolute', top: '60px', right: '-10px', zIndex: "12", display: (showEmojiPicker ? "block" : "none") }}
                // style={{ position: 'fixed', top: emojiPosition.top + "px", left: (emojiPosition.left - 100) + "px", zIndex: "12" }}
                // style={{ position: 'absolute', top: "30px", right: "0px", zIndex: "12" }}
                style={{ zIndex: "12" }}
                onSelect={handleOnSelectEmoji}
              />
            </Box>
          )}

          {!disableGif && (
            <>
              {/* <Tooltip label={
                            //@ts-ignore
                            typeof window !== "undefined" && window?.navigator?.userAgentData?.platform?.includes("mac") ? "Cmd-Shift-E" : "Ctrl-Shift-E"}
                        > */}
              <IconButton
                aria-label="add gif"
                // zIndex="9"
                variant="action"
                size="composer"
                isDisabled={isSavingGif || isSavingImage}
                isLoading={isSavingGif}
                // icon={<AiOutlineGif color="gray" size={iconSize} />}
                icon={
                  <Icon viewBox="0 0 18 14">
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 0H2C0.875 0 0 0.90625 0 2V12C0 13.125 0.875 14 2 14H16C17.0938 14 18 13.125 18 12V2C18 0.90625 17.0938 0 16 0ZM16.5 12C16.5 12.2812 16.25 12.5 16 12.5H2C1.71875 12.5 1.5 12.2812 1.5 12V2C1.5 1.75 1.71875 1.5 2 1.5H16C16.25 1.5 16.5 1.75 16.5 2V12ZM14.5 4.125H11.75C11.375 4.125 11.125 4.40625 11.125 4.75V9.25C11.125 9.625 11.375 9.875 11.75 9.875C12.0938 9.875 12.375 9.625 12.375 9.25V7.625H14C14.3438 7.625 14.625 7.375 14.625 7C14.625 6.65625 14.3438 6.375 14 6.375H12.375V5.375H14.5C14.8438 5.375 15.125 5.125 15.125 4.75C15.125 4.40625 14.8438 4.125 14.5 4.125ZM9.5 4.125C9.125 4.125 8.875 4.40625 8.875 4.75V9.25C8.875 9.625 9.125 9.875 9.5 9.875C9.84375 9.875 10.125 9.625 10.125 9.25V4.75C10.125 4.40625 9.84375 4.125 9.5 4.125ZM7.5 6.625H5.6875C5.34375 6.625 5.0625 6.90625 5.0625 7.25C5.0625 7.625 5.34375 7.875 5.6875 7.875H6.875V8.3125C6.21875 8.78125 5.125 8.71875 4.59375 8.15625C4.28125 7.84375 4.125 7.4375 4.125 7C4.125 6.59375 4.28125 6.1875 4.59375 5.875C5.1875 5.25 6.21875 5.25 6.84375 5.875C7.09375 6.125 7.5 6.125 7.71875 5.875C7.96875 5.625 7.96875 5.21875 7.71875 4.96875C6.65625 3.90625 4.78125 3.90625 3.6875 4.96875C3.15625 5.53125 2.875 6.25 2.875 7C2.875 7.78125 3.15625 8.5 3.6875 9.0625C4.25 9.59375 5 9.875 5.8125 9.875C6.625 9.875 7.375 9.59375 7.9375 9.0625C8.03125 8.9375 8.125 8.78125 8.125 8.59375V7.25C8.125 6.90625 7.84375 6.625 7.5 6.625Z"
                        fill="currentColor"
                      />
                    </svg>
                  </Icon>
                }
                onClick={() => {
                  setIsGifOpen(!isGifOpen);
                }}
              />
              {/* </Tooltip> */}
              {isGifOpen && (
                <Box
                  position="absolute"
                  right="0px"
                  top={isFullMode ? "-400px" : "40px"}
                  p="2"
                  borderRadius="xl"
                  border="1px"
                  borderColor={gifContainerBorder}
                  background={gifContainerBg}
                  ref={refGif}
                  zIndex="12"
                >
                  <GifBox onSelect={handleOnSelectGIF} />
                </Box>
              )}
            </>
          )}
          {isFullMode && (
            <>
              <Text
                mx={4}
                fontSize={"sm"}
                textStyle={textStyle["body.medium.inactive"]}
              >
                |
              </Text>
              {/* <Divider orientation="vertical" /> */}
              {!disableAiContinue && renderAIContinueIcon()}
              <IconButton
                aria-label="undo"
                variant="actionAI"
                // mr={2}
                // zIndex="9"
                size="sm"
                icon={<GrUndo size={iconSize} />}
                onClick={undoTextState}
                disabled={!canUndo}
              />
              {!disableReWrite && renderAIReWriteIcon()}
              <IconButton
                aria-label="redo"
                variant="actionAI"
                // mr={2}
                // zIndex="9"
                size="sm"
                icon={<GrRedo size={iconSize} />}
                onClick={redoTextState}
                disabled={!canRedo}
              />
            </>
          )}
        </Box>
      </Box>
      {isRichTextOpen && (
        <Box>
          {isFullMode && (
            <Center position={"absolute"} bottom={"42px"} left={"12px"}>
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDir="column"
              >
                {
                  <Flex mb={2} bg={gifContainerBg} rounded="xl" p={1} px={4}>
                    {renderRichTextOptions({ mb: 0 })}
                  </Flex>
                }
              </Flex>
            </Center>
          )}
          {!isFullMode && renderRichTextOptions()}
        </Box>
      )}
      {isPremium && (
        <Text
          fontWeight="700"
          color={colors.primary.lightMode[300]}
          bg={colors.primary.lightMode[80]}
          as={"span"}
          // textStyle={textStyle["body.bold.standard"]}
          fontSize={"xs"}
        >
          {" "}
          <TiTickOutline
            style={{
              display: "inline-block",
              position: "relative",
              top: "2px",
            }}
          />{" "}
          Long form post enabled
        </Text>
      )}
      {nbChar > postCharMax && !isPremium && (
        <Text
          textStyle={textStyle["body.medium.light"]}
          color="gray.600"
          mt={2}
          fontSize="xs"
        >
          <i>
            Long form posts on X/Twitter are only available to X/Twitter premium
            users. If you are a premium user and seeing this, please login &
            logout of TweetHunter and try again.
          </i>
        </Text>
      )}

      {nbChar > postCharMax && isPremium && (
        <Text
          textStyle={textStyle["body.medium.light"]}
          color="gray.600"
          mt={2}
          fontSize="xs"
        >
          <i>Long form posts are limited to 25000 characters on X/Twitter.</i>
        </Text>
      )}

      {!disableImage && textState?.files?.length > 0 && !isFullMode && (
        <Box
          position="relative"
          mt={1}
          display="flex"
          justifyContent="flex-end"
          overflow="visible"
          h="32px"
        >
          {textState?.files.map((x) => (
            <ImageThumbnail
              key={"ImageThumbnail-" + x}
              id={x}
              primaryAction={imageEditor && "edit"}
              openImageEditor={() => handleImageClick(x)}
            />
          ))}
        </Box>
      )}
      <Collapse in={isOpenAiReWrite} animateOpacity>
        <Flex
          alignItems="center"
          gap={2}
          px="4"
          pt="2"
          pb="2"
          border="none"
          textStyle={textStyle["body.bold.standard"]}
        >
          Improve your tweet with AI:
        </Flex>
        <Grid
          gridTemplateColumns={["repeat(1, 160px)", "repeat(2, 1fr)"]}
          rowGap="0"
          columnGap="2"
          p="0"
        >
          {prompts.map(({ icon: Icon, ...prompt }) => (
            <Button
              key={prompt.title}
              variant="actionAI"
              leftIcon={<Icon />}
              pl="4"
              w="100%"
              justifyContent="left"
              onClick={() => handleSendPrompt(prompt)}
            >
              {prompt.title}
            </Button>
          ))}
        </Grid>
      </Collapse>
      {/* <Box display="none"> */}
      {/* <Box display={files?.length > 0 ? "block" : "none"}> */}
      <Modal
        isOpen={isOpenImageUpload}
        onClose={onCloseImageUpload}
        size={tabIndex === 0 ? "md" : "2xl"}
        scrollBehavior={tabIndex === 1 && medias.length > 0 && "inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <Tabs
            onChange={(index) => {
              setTabIndex(index);
              if (index !== 1 || hasFetchedMedia) return;

              fetchData();
            }}
            overflowY={tabIndex === 1 && "scroll"}
          >
            <TabList>
              <Tab>Upload / Generate</Tab>
              <Tab>My Assets</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ModalHeader>Add an image, video or document</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <FilePond
                    ref={refPond}
                    dropOnElement={false}
                    dropValidation={mode == "tweet"}
                    allowPaste={true}
                    allowImagePreview={false}
                    beforeAddFile={handleBeforeAddFile}
                    onupdatefiles={handleOnUpdateFiles}
                    onprocessfile={handleProcessFile}
                    allowMultiple={true}
                    maxFiles={999}
                    name="files"
                    labelIdle={filePondLable}
                    credits={{ label: "", url: "" }}
                    className={classNames("upload-image-filepond", {
                      "filepond--panel-dark": colorMode === "dark",
                      "filepond--panel-light": colorMode === "light",
                    })}
                    server={{
                      process: handleFilePondServerProcess,
                    }}
                  />
                  <Text textAlign="center" mb={4}>
                    or
                  </Text>
                  <Text textAlign="center" mb={4} fontWeight="bold">
                    Generate an image with AI
                  </Text>
                  <GenerateImage
                    content={textState?.text}
                    onImageGenerated={(fileName) => {
                      addFileOnThread(fileName);
                      onCloseImageUpload();
                    }}
                  />
                </ModalBody>
              </TabPanel>
              <TabPanel>
                <ModalHeader>Select an asset from your library</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  {!hasFetchedMedia && (
                    <Box
                      width={"100%"}
                      mt={6}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Loader
                        color={
                          colorMode === "dark"
                            ? hslToHsla(colors.primary.lightMode[100], 1)
                            : hslToHsla(colors.primary.lightMode[400], 1)
                        }
                      />
                    </Box>
                  )}
                  {hasFetchedMedia && !!medias.length && (
                    <MediaMasonry
                      colorMode={colorMode}
                      medias={medias}
                      tweetContext={tweetContext}
                      modalMode
                      setSelectedMedias={setSelectedMedias}
                      selectedMedias={selectedMedias}
                    />
                  )}
                </ModalBody>
              </TabPanel>
            </TabPanels>
          </Tabs>
          {tabIndex === 1 && (
            <ModalFooter paddingTop={"10px !important"}>
              <Button
                onClick={handleUseMediaSelection}
                style={{
                  ...(selectedMedias.length > 0 && {
                    color: colors.success.lightMode[400],
                    border: `2px solid ${colors.success.lightMode[400]}`,
                  }),
                }}
              >
                Use selection ({selectedMedias.length})
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
      {!!selectedTweet?.full_text && getSimilarityWarning()}
      {!!tweetError?.text && !isFullMode && (
        <Text
          mt={0}
          color={
            tweetError?.type == "error"
              ? "red"
              : tweetError?.type == "warning"
                ? "orange.500"
                : "gray.500"
          }
          fontSize="sm"
        >
          <i>{tweetError?.text}</i>
        </Text>
      )}
      <ImageEditPopup
        isOpen={isOpenImageEdit}
        onClose={onCloseImageEdit}
        imageId={selectedImageId}
        onSave={(newMedia) => {
          editText(textState.text.replace(selectedImageId, newMedia.id));
          onCloseImageEdit();
        }}
      />
      <Modal isOpen={isOpenRewrite} onClose={onCloseRewrite}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Just to be sure</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            This will override your current content and re-write it using AI.
          </ModalBody>

          <ModalFooter>
            <Button
              variant={"primary"}
              onClick={() => {
                onCloseRewrite();
                handleAIReWriteClick();
              }}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
});
